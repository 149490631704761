import type { NextPage } from 'next'
import LandingContainer from '../features/Auth/components/LandingContainer'
import { useRouter } from 'next/router'
import PageHead from '../components/Admin/AdminHeader/PageHead'
import { Button } from 'antd'
import OrangeButton from '../components/Admin/utils/OrangeButton'

const Home: NextPage = () => {
    const router = useRouter()
    return (
        <LandingContainer>
            <PageHead title="Welcome!" />
            <div className="d-flex flex-column justify-content-center align-items-center vh-100 w-100">
                <OrangeButton size="large" onClick={() => router.push('/login')} type="primary">
                    Get Started
                </OrangeButton>
            </div>
        </LandingContainer>
    )
}

export default Home
